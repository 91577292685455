import styled from "styled-components";

export const GuestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
`;

export const GuestHeader = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

export const GuestParagraph = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
`;

export const GuestButton = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

import React from "react";
import { useRoutes } from "react-router";
import { Layout } from "../layout/Layout";
import { Main } from "../pages/main/Main";
import { About } from "../pages/about/About";
import { Skills } from "../pages/skills/Skills";
import { Archiving } from "../pages/archiving/Archiving";
import { Guest } from "../pages/guest/Guest";

export const Router = () => {
  const routes = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Main /> },
        { path: "/about", element: <About /> },
        { path: "/skills", element: <Skills /> },
        { path: "/archiving", element: <Archiving /> },
      ],
    },
    {
      path: "/*",
      element: <Layout />,
      children: [{ path: "/*", element: <Main /> }],
    },
    {
      path: "/guest",
      element: <Guest />,
    },
  ];
  return useRoutes(routes);
};

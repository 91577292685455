import React from "react";
import { useState } from "react";
import { GuestButton, GuestContainer, GuestHeader } from "./Guest.style";

export const Guest = () => {
  const [guestBookEntries, setGuestBookEntries] = useState([]);
  const [newEntry, setNewEntry] = useState("");

  const handleInputChange = (e) => {
    setNewEntry(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (newEntry.trim()) {
      setGuestBookEntries([...guestBookEntries, newEntry]);
      setNewEntry("");
    }
  };
  const handleDeleteEntry = (index) => {
    const updatedEntries = guestBookEntries.filter((_, i) => i !== index);
    setGuestBookEntries(updatedEntries);
  };

  return (
    <GuestContainer>
      <GuestHeader>Guest Book</GuestHeader>
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          value={newEntry}
          onChange={handleInputChange}
          placeholder="Leave a message"
        />
        <GuestButton type="submit">Submit</GuestButton>
      </form>
      <ul>
        {guestBookEntries.map((entry, index) => (
          <li key={index}>
            {entry}
            <GuestButton onClick={() => handleDeleteEntry(index)}>
              Delete
            </GuestButton>
          </li>
        ))}
      </ul>
    </GuestContainer>
  );
};

import React from "react";
import { List, Title, Top, Wrapper } from "./CareerCard.style";
import careerJson from "../../pages/about/career.json";
import { CareerDetailCard } from "./CareerDetailCard";
export const CareerCard = () => {
  const getMonthsWorked = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const months =
      (now.getFullYear() - start.getFullYear()) * 12 +
      (now.getMonth() - start.getMonth());
    return months;
  };

  const monthsWorked = getMonthsWorked("2023-03-01");

  return (
    <Wrapper>
      <Title>업무경험 WORK EXPERIENCE</Title>
      <Top.Wrapper>
        <Top.Title>
          <span>비트세이프</span>
          블록체인 IT 기업 개발팀
        </Top.Title>
        <Top.Date>2023.03 ~ 재직 중 ({monthsWorked}개월)</Top.Date>
        <Top.Content></Top.Content>
      </Top.Wrapper>

      <List.Wrapper>
        {careerJson.bitsafe.map((v) => (
          <CareerDetailCard key={v.id} title={v.title} content={v.content} />
        ))}
      </List.Wrapper>
    </Wrapper>
  );
};
